import { createClient } from '@supabase/supabase-js'

//URL DO NOT SHARE WITH ANYONE
const SUPABASE_URL= "https://kcncnigdvpwshpbniozw.supabase.co"

//SECRET KEY DO NOT SHARE WITH ANYONE
const SUPABASE_ANON_KEY= "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImtjbmNuaWdkdnB3c2hwYm5pb3p3Iiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTY4MzIzNjMxMSwiZXhwIjoxOTk4ODEyMzExfQ.w3itGGkyd8V_2e6kPBvUERJBTHZUt8Jxl6wII9-CVzk"

const supabaseUrl = SUPABASE_URL
const supabaseAnonKey = SUPABASE_ANON_KEY

//exporting the supabase client
export const supabase = createClient(supabaseUrl, supabaseAnonKey)