import React from 'react';
import styled from 'styled-components';
import "../assets/overrideCSS.css"

export default function EventListItem({ event, isToday }){
  return (
    <Card isToday={isToday}>
      <Title>{event.title}</Title>
      <Description>{event.description}</Description>
      <p style={{ fontWeight: "800" }}>Starts at: {new Date(event.start_time).toLocaleDateString()} {new Date(event.start_time).toLocaleTimeString([], { hour: 'numeric', minute: 'numeric', hour12: true })}</p>
      <p style={{ fontWeight: "800" }}>Ends at: {new Date(event.end_time).toLocaleDateString()} {new Date(event.end_time).toLocaleTimeString([], { hour: 'numeric', minute: 'numeric', hour12: true })}</p>
    </Card>
  );
};

const Card = styled.div`
    border: 2px solid rgba(79, 89, 246, 0.565);
    border-radius: 4px;
    padding: 35px;
    margin: 10px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 2px;
    background-color: ${(props) => props.isToday ? '#5e41de' : '#3f3f46'};
    width: 100%;
    text-align: left;
    font-size: 0.85rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 35px;
`;


const Title = styled.h3`
  margin-top: 0;
`;

const Description = styled.p`
  margin-bottom: 0;
`;
