import { useState} from 'react'
import styled from 'styled-components';
import "../assets/adminCSS.css"
import ClientNotes from '../Components/ClientNotes';
import ClientsList from '../Components/ClientsList';

export default function Clients({ session, isAdmin}) {
    const [id, setId] = useState(null)

    return (
        <Container>
            <ClientNotesContainer isAdmin={isAdmin}>
                <ClientNotes session={session} isAdmin={isAdmin} id={id}/>
            </ClientNotesContainer>
            <ClientsContainer isAdmin={isAdmin}>
                <ClientsList session={session} isAdmin={isAdmin} setId={setId}/>
            </ClientsContainer>
        </Container>
    );
}

const Container = styled.div`
    position: relative;
    height: 100%;
    height: 100svh;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 70px;
    padding:35px;
`;

const ClientNotesContainer = styled.div`
    height: 77%;
    width: 120%;
    border: ${({ isAdmin }) => (isAdmin ? '2px solid #639061;' : '2px solid #5039b7;')};
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const ClientsContainer = styled.div`
    height: 77%;
    width: 80%;
    border: 2px solid #639061;
    border: ${({ isAdmin }) => (isAdmin ? '2px solid #639061;' : '2px solid #5039b7;')};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;
