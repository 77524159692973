import { useState, useEffect } from 'react';
import { supabase } from './supabaseClient';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Auth from './BeastPages/Auth';
import Account from './BeastPages/Account';
import Overview from './BeastPages/Overview';
import UserEvents from './BeastPages/UserEvents';
import EventList from './BeastPages/UserEventList';
import NavFooter from './Components/NavFooter';
import AdminPanel from './BeastPages/AdminPanel';
import Clients from './BeastPages/Clients';
import { Toaster } from 'react-hot-toast';
import useCheckAdminUser from './Components/useCheckAdminUser';
import NotOnMobile from './Components/NotOnMobile';
import styled from 'styled-components';
import Home from './MainPage/Home';

export default function App() {
  const [session, setSession] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const isAdmin = useCheckAdminUser(session);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      console.log(session)
    });

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    const userAgent = window.navigator.userAgent.toLowerCase();
    setIsMobile(/iphone|ipad|ipod|android/i.test(userAgent));

  }, []);

  const renderForMobile = (Component, props) => {
    if (isMobile) {
      return <NotOnMobile supabase={supabase}/>;
    }
    return <Component {...props} />;
  };

  return (
    <BrowserRouter>
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{
          success: {
            duration: 3000,
            theme: {
              primary: 'green',
              secondary: 'black',
            },
          },
          error: {
            duration: 3000,
            theme: {
              primary: 'red',
              secondary: 'black',
            },
          },
          warn: {
            duration: 3000,
            theme: {
              primary: 'orange',
              secondary: 'black',
            },
          },
        }}
      />
      <Routes>
        <Route exact path="/" element={
          session ? <Navigate to="/overview" /> : <Home />
        } />
        <Route path="/account" element={
          session ? renderForMobile(Account, { key: session?.user.id, session, isAdmin }) : <Auth />
        } />
        <Route path="/overview" element={
          session ? renderForMobile(Overview, { key: session?.user.id, session, isAdmin }) : <Auth />
        } />
        <Route path="/myEvents" element={
          session ? renderForMobile(UserEvents, { key: session?.user.id, session, isAdmin }) : <Auth />
        } />
        <Route path="/myEventsList" element={
          session ? renderForMobile(EventList, { key: session?.user.id, session, isAdmin }) : <Auth />
        } />
        <Route path="/adminPanel" element={
          session ? renderForMobile(AdminPanel, { key: session?.user.id, session, isAdmin }) : <Auth />
        } />
        <Route path="/clients" element={
          session ? renderForMobile(Clients, { key: session?.user.id, session, isAdmin }) : <Auth />
        } />
      </Routes>
      <NavFooter session={session} isAdmin={isAdmin} />
    </BrowserRouter>
  );
}
