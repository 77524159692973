import { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';
import styled from 'styled-components';
import toast from 'react-hot-toast';
import moment from 'moment';

export default function ClientNotes({ session, isAdmin, id }) {
  const [notes, setNotes] = useState([]);
  const [newNote, setNewNote] = useState('');

  useEffect(() => {
    fetchNotes();
  }, [id]);

  const fetchNotes = async () => {
    try {
      const { data, error } = await supabase
        .from('client_notes')
        .select('*')
        .eq('client_id', id)
        .order('created_at', { ascending: true });
      if (error) {
        console.log(error);
      } else {
        setNotes(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addNote = async () => {
    if (newNote.trim() === '') {
      toast.error('Please enter a note');
      return;
    }

    try {
      const { error } = await supabase.from('client_notes').insert([
        {
          client_id: id,
          email: session.user.email,
          note: newNote,
        },
      ]);

      if (error) {
        console.log(error);
      } else {
        toast.success('Note added successfully!');
        setNewNote('');
        fetchNotes();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container>
      <Title isAdmin={isAdmin}>Client Notes</Title>
      <Notes isAdmin={isAdmin}>
        {notes.map((note) => (
          <>
            <Note key={note.id} isAdmin={isAdmin} className={note.email === session.user.email ? "right" : "left"}>
              {note.note}
              <FromWho isAdmin={isAdmin}>{note.email}</FromWho>
              <When isAdmin={isAdmin}>{moment(note.created_at).format('MMMM D [at] HH')}</When>
            </Note>
          </>
        ))}
      </Notes>
      <InputGroup>
        <Input
          isAdmin={isAdmin}
          placeholder="Enter a note"
          value={newNote}
          onChange={(e) => setNewNote(e.target.value)}
        />
        <SendButton isAdmin={isAdmin} onClick={addNote}>
          <svg
            width="24"
            height="24"
            fill="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M20.945 2.576a.6.6 0 0 1 .131.648L14.095 20.68a.901.901 0 0 1-1.595.149l-3.814-5.994-5.994-3.814a.9.9 0 0 1 .15-1.596l17.456-6.98a.6.6 0 0 1 .648.132v-.001ZM9.883 14.484l3.313 5.206 5.68-14.198-8.993 8.992Zm8.144-9.84L3.83 10.322l5.207 3.312 8.993-8.992h-.002Z"></path>
          </svg>
        </SendButton>
      </InputGroup>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  padding: 35px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  margin: 0 auto;
`;

const Title = styled.h1`
    position: absolute;
    top: -20px;
    background-color: #181818;
    border: ${({ isAdmin }) => (isAdmin ? '2px solid #639061;' : '2px solid #5039b7;')};
    padding: 5px 20px;
    border-radius: 50px;
    font-size: 1.2rem;
    margin-bottom: 1rem;
    color: ${({ isAdmin }) => (isAdmin ? '#639061;' : '#fff;')};
    text-align: center;
`;

const InputGroup = styled.div`
    position: relative;
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const Input = styled.textarea`
  width: 100%;
  padding: 5px 15px 5px 15px;
  border: ${({ isAdmin }) => (isAdmin ? '1px solid #639061;' : '2px solid #5039b7;')};
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  outline: none;
  resize: none;
  height: inherit;
  background-color: #282828;
  spellCheck: true;
`;

const SendButton = styled.button`
  background-color: ${({ isAdmin }) => (isAdmin ? '#639061;' : '#5039b7;')};
  color: #fff;
  border: none;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const Notes = styled.div`
    width: 100%;
    height: 90%;
    background-color: #282828;
    border: ${({ isAdmin }) => (isAdmin ? '1px solid #639061;' : '2px solid #5039b7;')};
    border-radius: 4px;
    padding: 35px;
    margin-bottom: 10px;
    overflow-y: scroll;
`;

const Note = styled.p`
    position: relative;
    background-color: ${({ isAdmin }) => (isAdmin ? '#639061;' : '#2d2554;')};
    padding: 10px 20px 40px 20px;
    min-width: 180px;
    width: fit-content;
    max-width: 45%;
    border-radius: 20px;
    border-top-right-radius: 10px;
    color: #fff;
    font-size: 0.9rem;
    margin-bottom: 25px;

    &.left {
    margin-right: auto;
    }

    &.right {
        margin-left: auto;
    }
`;

const When = styled.span`
  position: absolute;
  top: -10px;
  right: 0px;
  font-size: 0.6rem;
  font-weight: 800;
  margin-bottom: 0.5rem;
  -webkit-box-pack: justify;
  justify-content: space-between;
  background-color: ${({ isAdmin }) => (isAdmin ? '#639061;' : '#2d2554;')};
  padding: 0.2rem 0.8rem;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  z-index: 10;
  color: #ffffff60;
`;


const FromWho = styled.span`
position: absolute;
bottom: -10px;
left: 0px;
right: 0px;
font-size: 0.6rem;
font-weight: 800;
margin-bottom: 0.5rem;
-webkit-box-pack: justify;
justify-content: space-between;
background-color: ${({ isAdmin }) => (isAdmin ? '#466644;' : '#372E5F')};
padding: 0.4rem 0.8rem;
border-bottom-left-radius: 20px;
border-bottom-right-radius: 20px;
z-index: 10;
`;
