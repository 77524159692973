import { useState, useEffect } from 'react'
import { supabase } from '../supabaseClient'
import styled from 'styled-components';
import toast from 'react-hot-toast';

export default function Auth() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [otp, setOtp] = useState('');

  useEffect(() => {
    toast("App in development ...", { style: { backgroundColor: '#f79e42', color: '#000' }, icon: '⚒️' });
  }, []);

  const handleLogin = async (event) => {
    event.preventDefault();

    setLoading(true);

    // Check if email exists in users_info view
    const { data: users, error: usersError } = await supabase
      .from('users_info')
      .select('*')
      .eq('email', email)
      .single();

    if (usersError) {
      toast(`Error checking email`, { style: { backgroundColor: 'red', color: '#fff' } });
      setLoading(false);
      return;
    }

    if (!users) {
      toast('Email does not exist');
      setLoading(false);
      return;
    }

    const { error } = await supabase.auth.signInWithOtp({ email });

    if (error) {
      toast(error.error_description || error.message);
    } else {
      setShowOtpInput(true);
    }

    setLoading(false);
  };

  const handleVerifyOtp = async (event) => {
    event.preventDefault();

    setLoading(true);
    const { error: otpError } = await supabase.auth.verifyOtp({ email, token: otp, type: 'email' });

    if (otpError) {
      toast((otpError.error_description || otpError.message), { style: { backgroundColor: 'red', color: '#fff' } });
    }

    setLoading(false);
  }


  return (
    <Container>
      <div>
        <Title>Beast Scheduling
          <span>
            <svg width="35" height="35" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.375 2A.625.625 0 0 1 7 2.625v.625h10v-.625a.625.625 0 1 1 1.25 0v.625h1.25a2.5 2.5 0 0 1 2.5 2.5V19.5a2.5 2.5 0 0 1-2.5 2.5h-15A2.5 2.5 0 0 1 2 19.5V5.75a2.5 2.5 0 0 1 2.5-2.5h1.25v-.625A.625.625 0 0 1 6.375 2ZM4.5 4.5a1.25 1.25 0 0 0-1.25 1.25V7h17.5V5.75A1.25 1.25 0 0 0 19.5 4.5h-15Zm16.25 3.75H3.25V19.5a1.25 1.25 0 0 0 1.25 1.25h15a1.25 1.25 0 0 0 1.25-1.25V8.25Z"></path>
              <path d="M15.75 11.375a.624.624 0 0 1 .625-.625h1.25a.624.624 0 0 1 .625.625v1.25a.624.624 0 0 1-.625.625h-1.25a.624.624 0 0 1-.625-.625v-1.25Z"></path>
            </svg>
          </span>
        </Title>
        <p>Sign in via magic code with your email below</p>
        <form onSubmit={showOtpInput ? handleVerifyOtp : handleLogin}>
          <div>
            <Input
              type="email"
              placeholder="Your email"
              value={email}
              required={true}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className="input-border"></div>
          </div>
          <div>
            {showOtpInput ? (
              <>
                <Input
                  type="text"
                  placeholder="Enter the magic code"
                  value={otp}
                  required={true}
                  onChange={(e) => setOtp(e.target.value)}
                />
                <div className="input-border"></div>
              </>
            ) : (
              <MagicButton disabled={loading} onClick={handleLogin}>
                {loading ? <Span style={{ position: "inherit" }}>Loading</Span> : <Span>Send magic code</Span>}
              </MagicButton>
            )}
          </div>
          {showOtpInput && (
            <div>
              <MagicButton type="submit" disabled={loading}>
                {loading ? <Span style={{ position: "inherit" }}>Verifying</Span> : <Span style={{ position: "inherit" }}>Verify One Time Code</Span>}
              </MagicButton>
            </div>
          )}
        </form>
      </div>
      <Organizee>⚡Powered by webcity.dev</Organizee>
    </Container>
  )
}

const MagicButton = styled.button`
  position: relative;
  overflow: hidden;
  height: 3rem;
  padding: 0 2rem;
  border-radius: 1.5rem;
  background: #3d3a4e;
  background-size: 400%;
  color: #fff;
  border: none;

  &:hover::before {
    transform: scaleX(1);
  }

  .button-content {
    position: relative;
    z-index: 2;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    transform: scaleX(0);
    transform-origin: 0 50%;
    width: 100%;
    height: inherit;
    border-radius: inherit;
    background: linear-gradient(
      82.3deg,
      rgba(150, 93, 233, 1) 10.8%,
      rgba(99, 88, 238, 1) 94.3%
    );
    transition: all 0.475s;
  }
`;

const Container = styled.div`
    height: 100%;
    height: 100svh;
    width: 100%;
    display: grid;
    place-content: center;
    text-align: center;
    `;

const Input = styled.input`
--width-of-input: 200px;
--border-height: 1px;
--border-before-color: rgba(221, 221, 221, 0.39);
--border-after-color: #5891ff;
--input-hovered-color: #4985e01f;
    color: #fff;
    font-size: 0.9rem;
    background-color: transparent;
    width: 100%;
    box-sizing: border-box;
    padding-inline: 0.5em;
    padding-block: 0.7em;
    border: none;
    border-bottom: var(--border-height) solid var(--border-before-color);
    margin: 17.5px 0;
  
    &:hover {
      background: var(--input-hovered-color);
    }
  
    &:focus {
      outline: none;
    }
  
    & ~ .input-border {
      position: absolute;
      background: var(--border-after-color);
      width: 0%;
      height: 2px;
      bottom: 0;
      left: 0;
      transition: 0.3s;
    }
  
    &:focus ~ .input-border {
      width: 100%;
    }
  `;


const Span = styled.span`
  position: inherit;
  `;

const Title = styled.h1`
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #fff;
  display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
  `;

const Organizee = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 0.8rem;
  font-weight: 800;
  background: linear-gradient(
    82.3deg,
    rgba(150, 93, 233, 1) 10.8%,
    rgba(99, 88, 238, 1) 94.3%
  );
  `;