import { supabase } from '../supabaseClient';
import { useState } from 'react';
import toast from 'react-hot-toast';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';


export default function ClientCard({ client, isAdmin, onNameClick }) {
    const navigate = useNavigate();
    const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

    const handleDelete = async () => {
        try {
            const { error } = await supabase.from('clients').delete().eq('id', client.id);
            if (error) {
                console.error(error);
            } else {
                toast.success('Client deleted successfully!');
                closeDeleteConfirmation();
                navigate(0, { replace: true });
            }
        } catch (error) {
            console.error(error);
        }
    };

    const openDeleteConfirmation = () => {
        setDeleteConfirmationOpen(true);
    };

    const closeDeleteConfirmation = () => {
        setDeleteConfirmationOpen(false);
    };

    return (
        <ClientCardContainer key={client.id} isAdmin={isAdmin}>
            <Client>
                <UserName>
                    <Name isAdmin={isAdmin} onClick={onNameClick}>{(client.name) ? client.name : "n/a"}</Name>
                    <Email isAdmin={isAdmin}>{(client.email) ? client.email : "n/a"}</Email>
                    <Gender isAdmin={isAdmin}>{(client.gender) ? client.gender : "n/a"}</Gender>
                </UserName>
                <UserInfo isAdmin={isAdmin}>
                    <Phone isAdmin={isAdmin}>{(client.phone_number) ? client.phone_number : "n/a"}</Phone>
                    <Address isAdmin={isAdmin}>{(client.address) ? client.address : "n/a"}</Address>
                </UserInfo>
            </Client>
            {isAdmin && (
                <>
                    <DeleteButton onClick={openDeleteConfirmation}>
                        <svg width="20" height="20" fill="black" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M22 12a10 10 0 1 1-20 0 10 10 0 0 1 20 0ZM8.693 7.808a.626.626 0 1 0-.885.885L11.116 12l-3.308 3.307a.626.626 0 1 0 .885.885L12 12.884l3.307 3.308a.627.627 0 0 0 .885-.885L12.884 12l3.308-3.307a.627.627 0 0 0-.885-.885L12 11.116 8.693 7.808Z"></path>
                        </svg>
                    </DeleteButton>
                    {isDeleteConfirmationOpen && (
                        <DeleteConfirmationPopup>
                            <ConfirmationText>Are you sure you want to delete this user?</ConfirmationText>
                            <ButtonGroup>
                                <CancelButton onClick={closeDeleteConfirmation}>Cancel</CancelButton>
                                <DeleteConfirmButton onClick={handleDelete}>Delete</DeleteConfirmButton>
                            </ButtonGroup>
                        </DeleteConfirmationPopup>
                    )}
                </>
            )}
        </ClientCardContainer>
    )
}

const ClientCardContainer = styled.li`
    width: 100%;
    margin-bottom: 1rem;
    display: flex; 
    align-items: center;
    justify-content: center;
    gap:35px;
    background-color: #101010;
    border : ${({ isAdmin }) => (isAdmin ? '2px solid #639061;' : '2px solid #5039b7;')};
    padding: 15px 25px;
    border-radius: 15px;
    position: relative;
    font-size: 0.8rem;
    text-align: right;
`;

const Client = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
`;

const UserName = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;;
    gap: 5px;
`;

const Name = styled.h2`
    align-self: flex-start;
    font-size: 1rem;
    margin-right: auto;

    &:hover {
        opacity: 0.8;
        transform: scale(0.99);
        color:${({ isAdmin }) => (isAdmin ? '#639061;' : '#5039b7;')};
        cursor: pointer;
    }
`;

const Email = styled.p`
    font-size: 1em;
    background-color:${({ isAdmin }) => (isAdmin ? '#639061;' : '#5039b7;')};
    border-radius: 50px;
    padding: 5px 10px;
    color: white;
    text-align: center;
    margin: 2px 0;

    &:hover {
        opacity: 0.8;
        transform: scale(0.95);
    }
`;

const Phone = styled.p`
font-size: 1em;
background-color:${({ isAdmin }) => (isAdmin ? '#639061;' : '#5039b7;')};
    border-radius: 50px;
    padding: 5px 10px;
    color: white;
    text-align: center;
    margin: 2px 0;

    &:hover {
        opacity: 0.8;
        transform: scale(0.9);
    }
`;

const Address = styled.p`
font-size: 1em;
background-color:${({ isAdmin }) => (isAdmin ? '#639061;' : '#5039b7;')};
    border-radius: 50px;
    padding: 5px 10px;
    color: white;
    text-align: center;
    margin: 2px 0;
`;

const Gender = styled.p`
font-size: 1em;
background-color:${({ isAdmin }) => (isAdmin ? '#639061;' : '#5039b7;')};
    border-radius: 50px;
    padding: 5px 10px;
    color: white;
    text-align: center;
    margin: 2px 0;
`;

const UserInfo = styled.div`
  display: ${({ isAdmin }) => (isAdmin ? 'flex' : 'none')};
  flex-wrap: wrap;
  gap: 2px;
`;

const DeleteButton = styled.button`
  position:absolute;
  top:-10px;
  right:-10px;
  border-radius:100%;
  background-color: #639061;;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.8;
    background-color: red;
  }

`;

const DeleteConfirmationPopup = styled.div`
background-color: #1f1f1f;
border: 1px solid #557954;
padding: 35px;
border-radius: 8px;
  padding: 20px;
  width: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ConfirmationText = styled.p`
font-size: 0.8rem;
margin-bottom: 8px;
text-align: center;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
`;

const CancelButton = styled.button`
  background-color: #aaa;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #999;
  }
`;

const DeleteConfirmButton = styled.button`
  background-color:
#ff4646;
border: none;
padding: 8px 16px;
border-radius: 4px;
cursor: pointer;
color: #fff;

&:hover {
background-color: #ff3434;
}
`;