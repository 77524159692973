import { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';
import styled from 'styled-components';
import moment from 'moment';
import { toast } from 'react-hot-toast';



export default function Modal({ session, isOpen, onClose, startTime, endTime, fetchEvents }) {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [start_time, setStartTime] = useState('');
  const [end_time, setEndTime] = useState('');

  useEffect(() => {
    if (startTime) {
      setStartTime(moment(startTime).format('YYYY-MM-DDTHH:mm'));
    }
    if (endTime) {
      setEndTime(moment(endTime).format('YYYY-MM-DDTHH:mm'));
    }
  }, [startTime, endTime]);



  const handleSubmit = async (event) => {
    event.preventDefault();
  
    // Check if any of the inputs are empty
    if (!title || !description || !start_time || !end_time) {
      toast.error('Please fill in all the fields');
      return;
    }
  
    // Rest of the code for submitting the form
    console.dir(session);
    const { data, error } = await supabase.from('events').insert([
      {
        title,
        description,
        start_time,
        end_time,
        profiles_id: session.user.id,
        profiles_email: session.user.email,
      },
    ]);
    if (error) {
      console.log(error);
    } else {
      console.log(data);
    }
    setTitle('');
    setDescription('');
    onClose();
    fetchEvents();
  };

  return (
    <ModalOverlay isOpen={isOpen}>
      <ModalContent onSubmit={handleSubmit}>
        <ModalLabel>
          Title:
          <ModalInput type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
        </ModalLabel>
        <ModalLabel>
          Description:
          <ModalText type="text" value={description} onChange={(e) => setDescription(e.target.value)} />
        </ModalLabel>
        <ModalLabel>
          Start Time:
          <ModalInput type="datetime-local" value={start_time} onChange={(e) => setStartTime(new Date(e.target.value))} />
        </ModalLabel>
        <ModalLabel>
          End Time:
          <ModalInput type="datetime-local" value={end_time} onChange={(e) => setEndTime(e.target.value)} />
        </ModalLabel>
        <ModalButton type="submit">Create</ModalButton>
        <ModalButton type="button" onClick={onClose}>
          Cancel
        </ModalButton>
      </ModalContent>
    </ModalOverlay>
  );
}

const ModalOverlay = styled.div`
  display: ${(props) => (props.isOpen ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  background-color: #00000060;
  backdrop-filter: blur(5px);
  z-index: 100;
`;

const ModalContent = styled.form`
  display: flex;
  flex-direction: column;
  background-color: #111827;
  padding: 25px 35px;
  border-radius: 15px;
  filter: drop-shadow(0 0 10rem #4a3a8f);
  border: 2px solid rgba(167, 139, 250, 0.5);
`;

const ModalLabel = styled.label`
display: block;
color: rgba(156, 163, 175, 1);
margin-bottom: 4px;
`;

const ModalInput = styled.input`
width: 100%;
  border-radius: 0.375rem;
  border: 1px solid rgba(55, 65, 81, 1);
  outline: 0;
  background-color: rgba(17, 24, 39, 1);
  padding: 0.75rem 1rem;
  color: rgba(243, 244, 246, 1);

  &:focus {
    border-color: rgba(167, 139, 250);
  }
`;

const ModalText = styled.textarea`
width: 100%;
  border-radius: 0.375rem;
  border: 1px solid rgba(55, 65, 81, 1);
  outline: 0;
  background-color: rgba(17, 24, 39, 1);
  padding: 0.75rem 1rem;
  color: rgba(243, 244, 246, 1);
  resize: none;

  &:focus {
    border-color: rgba(167, 139, 250);
  }
`;

const ModalButton = styled.button`
display: block;
width: 100%;
background-color: rgba(167, 139, 250, 1);
padding: 0.75rem;
text-align: center;
color: rgba(17, 24, 39, 1);
border: none;
border-radius: 0.375rem;
font-weight: 600;
margin: 0.3rem 0;

&:first-of-type {
  border-bottom-left-radius: 0.100rem;
  border-bottom-right-radius: 0.100rem;
}

&:last-of-type {
  border-top-left-radius: 0.100rem;
  border-top-right-radius: 0.100rem;
}

&:hover {
  background-color: #af7729;
}
`;
