import { useState, useEffect } from 'react'
import { supabase } from '../supabaseClient'
import styled from 'styled-components';
import Profile from '../Components/Profile'
import { Link } from 'react-router-dom';
import useCheckAdminUser from './useCheckAdminUser';


export default function NavFooter({ session, isAdmin }) {
    const [loading, setLoading] = useState(true)
    const [username, setUsername] = useState(null)
    const [full_name, setFullname] = useState(null)
    const [website, setWebsite] = useState(null)
    const [avatar_url, setAvatarUrl] = useState(null)

    useEffect(() => {
        setAvatarUrl(null)
        async function getProfile() {
            setLoading(true);

            if (!session || !session.user) {
                setLoading(false);
                return;
            }

            const { user } = session;

            let { data, error } = await supabase
                .from('profiles')
                .select(`username, website, full_name, avatar_url`)
                .eq('id', user.id)
                .single();

            if (error) {
                console.warn(error);
            } else if (data) {
                setUsername(data.username);
                setFullname(data.full_name);
                setWebsite(data.website);
                setAvatarUrl(data.avatar_url);
            }

            setLoading(false);
        }


        getProfile()
    }, [session])

    return (
        <Footer>
            <Options>
                {session && isAdmin ? (
                    <StyledLink isAdmin={isAdmin} to="/adminPanel">  Admin Panel   </StyledLink>
                ) : null}
                {session && (
                    <StyledLink isAdmin={isAdmin} to="/overview">
                        Overview
                    </StyledLink>)}
                {session && !isAdmin ? (
                    <StyledLink isAdmin={isAdmin} to="myEvents">  My Events   </StyledLink>
                ) : null}
                {session && (
                    <StyledLink isAdmin={isAdmin} to="/clients">
                        Clients
                    </StyledLink>)}
            </Options>
            {session && (
                <AccountLink to="/account">
                    <Profile url={avatar_url} size={45} session={session} isAdmin={isAdmin} />
                </AccountLink>
            )}
        </Footer>
    )
}

const Footer = styled.footer`
    height: max-content;
    width: 100%;
    position: fixed;
    bottom: 15px;
    left: 0;
    display:flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0 35px;
    gap:17.5px;
`;

const Options = styled.div`
display: flex;  
gap: 17.5px;

@media (max-width: 768px) {
    width: 100%;
    justify-content: center;
    gap: 10px;
}
`;

const StyledLink = styled(Link)`
display: flex;
justify-content: center;
align-items: center;
padding: 6px 12px;
gap: 8px;
height: 36px;
width: fit-content;
border: none;
white-space: nowrap;
background: ${({ isAdmin }) => (isAdmin ? '#a5faa233' : '#5e41de33')};
border-radius: 20px;
cursor: pointer;
text-decoration: none;
color: ${({ isAdmin }) => (isAdmin ? '#a5faa2' : '#b3a2fa')};

&:hover {
    background: ${({ isAdmin }) => (isAdmin ? '#a5faa288' : '#5e41de88')};
}
`;

const AccountLink = styled(Link)`
  cursor: pointer;
  margin-left: auto;

  @media (max-width: 768px) {
    position: fixed;
    top: 15px;
    right: 15px;
    }

    
`;