import { useState, useEffect } from 'react'
import { supabase } from '../supabaseClient'
import styled, { keyframes } from 'styled-components';
import Avatar from '../Components/Avatar'
import { useNavigate } from 'react-router-dom';

export default function Account({ session, isAdmin }) {
    const [loading, setLoading] = useState(true)
    const [username, setUsername] = useState(null)
    const [full_name, setFullname] = useState(null)
    const [phone, setPhone] = useState(null)
    const [website, setWebsite] = useState(null)
    const [avatar_url, setAvatarUrl] = useState(null)
    const navigate = useNavigate()

    useEffect(() => {
        async function getProfile() {
            setLoading(true)
            const { user } = session

            let { data, error } = await supabase
                .from('profiles')
                .select(`username, website, full_name, phone, avatar_url`)
                .eq('id', user.id)
                .single()

            if (error) {
                console.warn(error)
            } else if (data) {
                setUsername(data.username)
                setFullname(data.full_name)
                setPhone(data.phone)
                setWebsite(data.website)
                setAvatarUrl(data.avatar_url)
            }

            setLoading(false)
        }

        getProfile()
    }, [session])

    async function updateProfile(event) {
        event.preventDefault()

        setLoading(true)
        const { user } = session

        const updates = {
            id: user.id,
            username,
            full_name,
            phone,
            website,
            avatar_url,
            updated_at: new Date(),
        }

        let { error } = await supabase.from('profiles').upsert(updates)

        if (error) {
            alert(error.message)
        }
        setLoading(false)
    }

    return (
        <Container>
            <Form onSubmit={updateProfile} className="form-widget">
            {(isAdmin ?
                <Avatar
                    url={avatar_url}
                    size={150}
                    isAdmin={true}
                    onUpload={(event, url) => {
                        setAvatarUrl(url)
                        updateProfile(event)
                    }}
                /> :
                <Avatar
                url={avatar_url}
                size={150}
                isAdmin={false}
                onUpload={(event, url) => {
                    setAvatarUrl(url)
                    updateProfile(event)
                }}
            />
            )}
                <RowDiv>
                    <Input id="email" type="text" placeholder="Your email address" value={session.user.email} disabled />
                </RowDiv>
                <RowDiv>
                    <Input
                        id="username"
                        type="text"
                        required
                        placeholder="Your username"
                        value={username || ''}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </RowDiv>
                <RowDiv>
                    <Input
                        id="full_name"
                        type="text"
                        required
                        placeholder="Your full name"
                        value={full_name || ''}
                        onChange={(e) => setFullname(e.target.value)}
                    />
                </RowDiv>
                <RowDiv>
                    <Input
                        id="phone"
                        type="phone"
                        placeholder="Your phone number"
                        value={phone || ''}
                        onChange={(e) => setPhone(e.target.value)}
                    />
                </RowDiv>
                <RowDiv>
                    <Input
                        id="website"
                        type="url"
                        placeholder="Your instagram account"
                        value={website || ''}
                        onChange={(e) => setWebsite(e.target.value)}
                    />
                </RowDiv>
                <br />
                <RowDiv>
                        <EditButton className="button block" type="button" isAdmin={isAdmin} onClick={() => {supabase.auth.signOut();}}>
                            <Label className="label" isAdmin={isAdmin}>Sign Out</Label>
                        </EditButton>
              
                        <EditButton type="submit" disabled={loading} isAdmin={isAdmin}>
                            <Label className="label" isAdmin={isAdmin}>{loading ? 'Loading ...' : 'Update'}</Label>
                        </EditButton>
                </RowDiv>
            </Form>
        </Container>
    )
}

const Container = styled.div`
    height: 100%;
    height: 100svh;
    width: 100%;
    display: grid;
    place-content: center;
    text-align: center;
    `;

const Input = styled.input`
--width-of-input: 200px;
--border-height: 1px;
--border-before-color: rgba(221, 221, 221, 0.39);
--border-after-color: #5891ff;
--input-hovered-color: #4985e01f;
    color: #fff;
    font-size: 0.9rem;
    background-color: transparent;
    width: 100%;
    box-sizing: border-box;
    padding-inline: 0.5em;
    border: none;
    border-bottom: var(--border-height) solid var(--border-before-color);
    margin: 17.5px 0;
  
    &:hover {
      background: var(--input-hovered-color);
    }
  
    &:focus {
      outline: none;
    }
  
    & ~ .input-border {
      position: absolute;
      background: var(--border-after-color);
      width: 0%;
      height: 2px;
      bottom: 0;
      left: 0;
      transition: 0.3s;
    }
  
    &:focus ~ .input-border {
      width: 100%;
    }
  `;

const Form = styled.form`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
width: 300px;
`;

const RowDiv = styled.div`
display: flex;
align-items: center;
justify-content: center;
gap: 1em;
width: 100%;
`;



const spinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

const EditButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  gap: 8px;
  height: 36px;
  width: 120px;
  border: none;
  background: ${({ isAdmin }) => (isAdmin ? '#a5faa233' : '#5e41de33')};
  border-radius: 20px;
  cursor: pointer;

  &:hover {
    background: ${({ isAdmin }) => (isAdmin ? '#a5faa24d' : '#5e41de4d')};
  }
`;

const Label = styled.span`
  line-height: 20px;
  font-size: 17px;
  color: ${({ isAdmin }) => (isAdmin ? '#a5faa2' : '#b3a2fa')};
  font-family: sans-serif;
  letter-spacing: 1px;
  pointer-events: none;
`;