import { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import styled from 'styled-components';
import Calendar from '../Components/Calendar';
import Modal from '../Components/Modal';


import '../assets/overrideCSS.css';
import { Link } from 'react-router-dom';

export default function UserEvents({ session }) {
    const [events, setEvents] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);

    const fetchEvents = async () => {
        const { data: events, error } = await supabase
            .from('events')
            .select('*')
            .eq('profiles_id', session.user.id)
        if (error) {
            console.log(error);
        } else {
            setEvents(events);
            console.log(events);
        }
    };

    useEffect(() => {
        fetchEvents();
    }, [session]);

    const toggleModal = (info) => {
        console.log(info);
        if (info && info.start) {
          setStartTime(info.start);
        }
        if (info && info.end) {
            setEndTime(info.end);
        }
        setModalOpen(!modalOpen);
      };

    return (
        <>
            <Container>
                <Calendar events={events} setEvents={setEvents} toggleModal={toggleModal} session={session} fetchEvents={fetchEvents} />
                <StyledLink to="/myEventsList">List View</StyledLink>
            </Container>
            <Modal session={session} isOpen={modalOpen} onClose={toggleModal} startTime={startTime} endTime={endTime} fetchEvents={fetchEvents} />
        </>
    );
}

const Container = styled.div`
    position: relative;	
    height: 100%;
    height: 100svh;
    width: 100%;
    display: grid;
    place-items: center;
    text-align: center;
`;


const StyledLink = styled(Link)`
display: flex;
justify-content: center;
align-items: center;
padding: 6px 12px;
gap: 8px;
height: 36px;
width: fit-content;
border: none;
background: #5e41de;
border-radius: 20px;
color: #b3a2fa;

position: absolute;
bottom: 108px;
right: 85px;
text-decoration: none;
color: white;
cursor: pointer;
z-index: 10;

&:hover {
  background: #5e41de4d;
}
`;