import { useEffect, useState } from 'react'
import { supabase } from '../supabaseClient'
import styled from 'styled-components';
import blankProfile from '../assets/images/blank_profile.jpg';

export default function Profile({ url, size, isAdmin, session}) {
  const [avatarUrl, setAvatarUrl] = useState(null)

  useEffect(() => {
    if (url) downloadImage(url)
  }, [url, session])

  async function downloadImage(path) {
    try {
      const { data, error } = await supabase.storage.from('avatars').download(path)
      if (error) {
        throw error
      }
      const url = URL.createObjectURL(data)
      setAvatarUrl(url)
    } catch (error) {
      console.log('Error downloading image: ', error.message)
    }
  }

  return (
    <>
      {avatarUrl ? (
        <AvatarImage
          src={avatarUrl}
          alt="Avatar"
          isAdmin={isAdmin}
          className="avatar image"
          style={{ height: size, width: size }} 
        />
      ) : (
        <AvatarImage
          src={blankProfile}
          alt="Avatar"
          className="avatar image"
          isAdmin={isAdmin}
          style={{ height: size, width: size }}
        />
      )}
    </>
  )
}


const AvatarImage = styled.img `
  border-radius: 100%;
  object-fit: cover;
  object-position: center;
  border: ${({ isAdmin }) => (isAdmin ? '3px solid #557954' : '3px solid #5e41de')};
`;
