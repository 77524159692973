import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";

import logo from "../assets/images/logo.svg";

export default function Home() {
    const [showSplash, setShowSplash] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowSplash(false);
        }, 3000);  // Show the splash screen for 3 seconds

        return () => clearTimeout(timer);
    }, []);

    return (
        <Container>
            {showSplash ? <SplashScreen /> : null}
            <Menu>
                <MenuItem to="/account">Beast</MenuItem>
                <MenuItem>Merch</MenuItem>
                <MenuItem>Playlist</MenuItem>
            </Menu>
        </Container>
    );
}

const fadeIn = keyframes`
    from {
        opacity: 1;
    }
    to {
        opacity: 1;
    }
`;

const fadeOut = keyframes`
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
`;

const SplashScreen = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #181818;
    animation: ${fadeIn} 1.5s forwards, ${fadeOut} 1.5s 1.5s forwards;
    z-index: 10; // To ensure it appears above everything else

    &::before {
        content: '';
        background: url(${logo}) no-repeat center;
        background-size: contain;
        width: 50%;
        height: 50%;
        display: block;
    }
`;

const Container = styled.div`
    height: 100dvh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px;
`;

const Menu = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 70px;
    padding:35px;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
`;

const MenuItem = styled(Link)`
    height: 100%;
    width: 100%;
    border: 2px solid #765aec;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #fff;
    font-size: 3.5rem;

    &:hover {
        background-color: #282828;
        color: white;
    }

    &:active {
        background-color: #765aec50;
        color: white;
    }
`;
