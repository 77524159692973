import { useState, useEffect } from 'react'
import { supabase } from '../supabaseClient'
import styled from 'styled-components';
import toast, { Toaster } from 'react-hot-toast';



export default function NotOnMobile({supabase}) {

  useEffect(() => {
    toast("Mobile interface in development", { style: { backgroundColor: '#f79e42', color: '#000' }});
  }, []);

  return (
    <>
    <Toaster />
    <Container>
      <div>
        <Title>Oh no ...</Title>
        <p>Sorry, V2F-Scheduler doesn't <br /> support mobile devices yet</p>
        <BackButton onClick={() => {supabase.auth.signOut()}}>back</BackButton>
      </div>
    </Container>
    </>
  )
}

const Container = styled.div`
    height: 100svh;
    width: 100%;
    display: grid;
    place-content: center;
    text-align: center;
    `;

const BackButton = styled.button`
  background-color: #f79e42;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  color: #000;
  font-weight: 700;
  cursor: pointer;
  margin-top: 1rem;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #f7b342;
  }
  `;

const Title = styled.h1`
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #fff;
  display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
  `;