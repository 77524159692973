import { useState } from 'react';
import { supabase } from '../supabaseClient';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment';
import EventCard from './EventCard';
import toast from 'react-hot-toast';

export default function Calendar({ events, setEvents, toggleModal, session, fetchEvents, isAdmin }) {
  const [isDragging, setIsDragging] = useState(false);
  const navigate = useNavigate();

  const formatEvent = (event) => {
    return {
      id: event.id,
      email: event.profiles_email,
      title: event.title,
      start: event.start_time,
      end: event.end_time,
      description: event.description,
      backgroundColor: isAdmin ? '#639061' : session.user.id === event.profiles_id ? '#5E41DE' : '#AF7729',
      profiles_id: event.profiles_id
    };
  };

  const renderEventContent = (eventInfo) => { return <EventCard eventInfo={eventInfo} /> }

  const handleEventDragStart = () => setIsDragging(true);

  const handleEventDrop = async (eventDropInfo) => {
    const { id } = eventDropInfo.event;
    const { start, end } = eventDropInfo.event;
    const newStartTime = eventDropInfo.start ? eventDropInfo.start.toISOString() : start;
    const newEndTime = eventDropInfo.end ? eventDropInfo.end.toISOString() : end;

    if (session.user.id !== eventDropInfo.event.extendedProps.profiles_id && !isAdmin) {
      toast("You can only move your own events.", {style: {backgroundColor: '#5E41DE', color: '#fff'}});
      return;
    }

    try {
      const newStartTimeFormatted = moment(newStartTime).tz('America/New_York').format('MM/DD/YYYY h:mm A');
      const newEndTimeFormatted = moment(newEndTime).tz('America/New_York').format('MM/DD/YYYY h:mm A');
      const { error } = await supabase
        .from('events')
        .update({
          start_time: newStartTimeFormatted,
          end_time: newEndTimeFormatted,
        })
        .eq('id', id);

      if (error) {
        throw error;
      } else {
        fetchEvents();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEventResize = async (resizeInfo) => {
    const { id } = resizeInfo.event;
    const { start, end } = resizeInfo.event;
    const newStartTime = start.toISOString();
    const newEndTime = end.toISOString();

    if (session.user.id !== resizeInfo.event.extendedProps.profiles_id && !isAdmin) {
      toast("You can only resize your own events.", {style: {backgroundColor: '#5E41DE', color: '#fff'}});
      fetchEvents();
      return;
    }

    try {
      const newStartTimeFormatted = moment(newStartTime).tz('America/New_York').format('MM/DD/YYYY h:mm A');
      const newEndTimeFormatted = moment(newEndTime).tz('America/New_York').format('MM/DD/YYYY h:mm A');

      const { error } = await supabase
        .from('events')
        .update({
          start_time: newStartTimeFormatted,
          end_time: newEndTimeFormatted,
        })
        .eq('id', id);

      if (error) {
        throw error;
      } else {
        fetchEvents();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEventRemove = async (eventRemoveInfo) => {
    const { id } = eventRemoveInfo.event;

    if (session.user.id !== eventRemoveInfo.event.extendedProps.profiles_id && !isAdmin) {
      console.log(eventRemoveInfo.event)
      toast("You can only delete your own events.", {style: {backgroundColor: '#5E41DE', color: '#fff'}});
      fetchEvents();
      return;
    }

    try {
      const { error } = await supabase
        .from('events')
        .delete()
        .eq('id', id);

      if (error) {
        throw error;
      } else {
        fetchEvents();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEventDragStop = (info) => {
    const jsEvent = info.jsEvent;
    const trashEl = document.getElementById('calendarTrash');
    const trashRect = trashEl.getBoundingClientRect();
    setIsDragging(false);

    const x1 = trashRect.left;
    const x2 = trashRect.right;
    const y1 = trashRect.top;
    const y2 = trashRect.bottom;

    if (
      jsEvent &&
      jsEvent.pageX >= x1 &&
      jsEvent.pageX <= x2 &&
      jsEvent.pageY >= y1 &&
      jsEvent.pageY <= y2
    ) {
      // Delete the event
      const { id } = info.event;
      handleEventRemove(info)
      setEvents((prevEvents) =>
        prevEvents.filter((evt) => evt.id !== id)
      );
    }
  };


  return (
    <>
      <FullCalendar
        plugins={[
          dayGridPlugin,
          timeGridPlugin,
          momentTimezonePlugin,
          interactionPlugin,
        ]}
        events={events.map((event) => formatEvent(event))}
        eventContent={renderEventContent}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        }}
        initialView="timeGridWeek"
        slotEventOverlap={false}
        editable={true}
        eventDrop={handleEventDrop}
        eventResize={handleEventResize}
        selectable={true}
        weekends={true}
        allDaySlot={false}
        nowIndicator={true}
        nowIndicatorContent={() => null}
        eventOverlap={false}
        selectOverlap={false}
        eventDragStop={handleEventDragStop}
        eventDragStart={handleEventDragStart}
        select={(selectInfo) => toggleModal(selectInfo)}
      />
      {isDragging && (
        <TrashDrop id="calendarTrash">
          <svg width="31" height="31" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.96 3.6h3.6a.6.6 0 0 1 .6.6v1.2h-4.8V4.2a.6.6 0 0 1 .6-.6Zm5.4 1.8V4.2a1.8 1.8 0 0 0-1.8-1.8h-3.6a1.8 1.8 0 0 0-1.8 1.8v1.2H5.168a.6.6 0 0 0-.012 0H3.96a.6.6 0 0 0 0 1.2h.646L5.63 19.392A2.4 2.4 0 0 0 8.022 21.6h7.476a2.4 2.4 0 0 0 2.393-2.208L18.915 6.6h.645a.6.6 0 0 0 0-1.2h-1.194a.61.61 0 0 0-.012 0H15.36Zm2.35 1.2-1.015 12.696a1.2 1.2 0 0 1-1.197 1.104H8.022a1.2 1.2 0 0 1-1.196-1.104L5.811 6.6H17.71ZM8.726 7.8a.6.6 0 0 1 .633.564l.6 10.2a.6.6 0 0 1-1.197.072l-.602-10.2a.6.6 0 0 1 .564-.636h.002Zm6.07 0a.6.6 0 0 1 .563.636l-.6 10.2a.6.6 0 1 1-1.197-.072l.6-10.2a.6.6 0 0 1 .633-.564Zm-3.036 0a.6.6 0 0 1 .6.6v10.2a.6.6 0 1 1-1.2 0V8.4a.6.6 0 0 1 .6-.6Z"></path>
          </svg>
        </TrashDrop>)}
    </>
  )
}

const TrashDrop = styled.div`
    height: 100%;
    width: 45px;
    display: flex;
    place-content: center;
    place-items: center;
    position: absolute;
    bottom: 0;
    right: 20px;
    font-size: 0.7rem;
    font-weight: 800;
`;