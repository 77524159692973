import { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';

export default function useCheckAdminUser(session) {
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const checkAdmin = async () => {
      const { user } = session;
      if (user) {
        const { data, error } = await supabase
          .from('admin')
          .select('*')
          .eq('id', user.id)
          .single();
        if (data && !error) {
          setIsAdmin(true);
          // Additional logic or actions for admin user
        } else {
          setIsAdmin(false);
          // Logic or actions for non-admin user
        }
      } else {
        setIsAdmin(false);
        // Logic or actions for non-authenticated user
      }
    };

    checkAdmin();
  }, [session]);

  return isAdmin;
}
