import { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';
import styled from 'styled-components';
import toast from 'react-hot-toast';
import ClientCard from './ClientCard';

export default function ClientsList({ session, isAdmin, setId }) {
    const [clients, setClients] = useState([]);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [gender, setGender] = useState('');

    const [selectedClient, setSelectedClient] = useState(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editedEmail, setEditedEmail] = useState('');
    const [editedName, setEditedName] = useState('');
    const [editedPhone, setEditedPhone] = useState('');
    const [editedAddress, setEditedAddress] = useState('');
    const [editedGender, setEditedGender] = useState('');


    useEffect(() => {
        fetchClients();
    }, [clients])

    // Fetch clients
    const fetchClients = async () => {
        try {
            const { data: clients, error } = await supabase.from('clients').select('*');
            if (error) {
                console.log(error);
            } else {
                setClients(clients);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleActionButtonClick = () => {
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
    };

    const CreateClient = async () => {
        if (!name || !email) {
            toast.error('Name and Email are required fields');
            return;
        }

        // Email validation regex pattern
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!emailPattern.test(email)) {
            toast.error('Invalid email format');
            return;
        }

        try {
            const { data, error } = await supabase.from('clients').insert([
                {
                    name: name,
                    email: email,
                    phone_number: phone,
                    address: address,
                    gender: gender
                }
            ]);

            if (error) {
                console.log(error);
                toast.error('Failed to add client');
            } else {
                toast.success('Client added successfully!');
                closePopup();
            }
        } catch (error) {
            console.log(error);
            toast.error('Failed to add client');
        }

        setName('');
        setEmail('');
        setPhone('');
        setAddress('');
        setGender('');
    };

    const handleClientNameClick = (client) => {
        if(!isAdmin) return; // Only admins can edit clients
        setSelectedClient(client);
        setEditedEmail(client.email);
        setEditedName(client.name);
        setEditedPhone(client.phone_number);
        setEditedAddress(client.address);
        setEditedGender(client.gender);
        setIsEditModalOpen(true);
    };

    const updateClient = async () => {
        if (!editedName || !editedEmail) {
            toast.error('Name and Email are required fields');
            return;
        }

        // Email validation regex pattern
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!emailPattern.test(editedEmail)) {
            toast.error('Invalid email format');
            return;
        }

        try {
            const { data, error } = await supabase
                .from('clients')
                .update({
                    name: editedName,
                    email: editedEmail,
                    phone_number: editedPhone,
                    address: editedAddress,
                    gender: editedGender,
                })
                .eq('id', selectedClient.id);

            if (error) {
                console.log(error);
                toast.error('Failed to update client');
            } else {
                toast.success('Client updated successfully!');
                closeEditModal();
            }
        } catch (error) {
            console.log(error);
            toast.error('Failed to update client');
        }
    };

    const closeEditModal = () => {
        setIsEditModalOpen(false);
        setSelectedClient(null);
        setEditedEmail('');
        setEditedName('');
        setEditedPhone('');
        setEditedAddress('');
        setEditedGender('');
    };



    return (
        <Container>
            <Title isAdmin={isAdmin}>Clients</Title>
            <Clients isAdmin={isAdmin}>
                {clients.map((client) => <Clickable onClick={() => setId(client.id)}><ClientCard client={client} isAdmin={isAdmin} onNameClick={() => handleClientNameClick(client)} /></Clickable>)}
            </Clients>
            <FloatingActionButton isAdmin={isAdmin} onClick={handleActionButtonClick}>
                <svg width="45" height="45" fill="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 7a.625.625 0 0 1 .625.625v3.75h3.75a.624.624 0 1 1 0 1.25h-3.75v3.75a.624.624 0 1 1-1.25 0v-3.75h-3.75a.625.625 0 1 1 0-1.25h3.75v-3.75A.625.625 0 0 1 12 7Z"></path>
                </svg>
            </FloatingActionButton>
            {isPopupOpen && (
                <Popup>
                    <Title isAdmin={isAdmin}>Add Client</Title>
                    <CloseButton onClick={closePopup}>
                        <svg width="25" height="25" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M19.505 4.975a.6.6 0 0 1 0 .85l-13.2 13.2a.6.6 0 0 1-.85-.85l13.2-13.2a.598.598 0 0 1 .85 0Z" clip-rule="evenodd"></path>
                            <path fill-rule="evenodd" d="M5.456 4.975a.6.6 0 0 0 0 .85l13.2 13.2a.6.6 0 1 0 .85-.85l-13.2-13.2a.6.6 0 0 0-.85 0Z" clip-rule="evenodd"></path>
                        </svg>
                    </CloseButton>
                    <Input required placeholder="Enter Name" onChange={(e) => setName(e.target.value)} />
                    <Input required type="email" placeholder="Enter Email" onChange={(e) => setEmail(e.target.value)} />
                    <Input required placeholder="Enter Phone" onChange={(e) => setPhone(e.target.value)} />
                    <Input required placeholder="Enter Address" onChange={(e) => setAddress(e.target.value)} />
                    <Input required placeholder="Enter Gender" onChange={(e) => setGender(e.target.value)} />
                    <SendButton onClick={CreateClient}>Send</SendButton>
                </Popup>
            )}
            {isEditModalOpen && selectedClient && (
                <Popup>
                    <Title isAdmin={isAdmin}>Edit Client</Title>
                    <CloseButton onClick={closeEditModal}>
                        <svg
                            width="25"
                            height="25"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                d="M19.505 4.975a.6.6 0 0 1 0 .85l-13.2 13.2a.6.6 0 0 1-.85-.85l13.2-13.2a.598.598 0 0 1 .85 0Z"
                                clipRule="evenodd"
                            ></path>
                            <path
                                fillRule="evenodd"
                                d="M5.456 4.975a.6.6 0 0 0 0 .85l13.2 13.2a.6.6 0 1 0 .85-.85l-13.2-13.2a.6.6 0 0 0-.85 0Z"
                                clipRule="evenodd"
                            ></path>
                        </svg>
                    </CloseButton>
                    <Input
                        required
                        placeholder="Enter Name"
                        value={editedName}
                        onChange={(e) => setEditedName(e.target.value)}
                    />
                    <Input
                        required
                        type="email"
                        placeholder="Enter Email"
                        value={editedEmail}
                        onChange={(e) => setEditedEmail(e.target.value)}
                    />
                    <Input
                        required
                        placeholder="Enter Phone"
                        value={editedPhone}
                        onChange={(e) => setEditedPhone(e.target.value)}
                    />
                    <Input
                        required
                        placeholder="Enter Address"
                        value={editedAddress}
                        onChange={(e) => setEditedAddress(e.target.value)}
                    />
                    <Input
                        required
                        placeholder="Enter Gender"
                        value={editedGender}
                        onChange={(e) => setEditedGender(e.target.value)}
                    />
                    <SendButton onClick={updateClient}>Update</SendButton>
                </Popup>
            )}

        </Container>
    );
}

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

const Title = styled.h1`
    position: absolute;
    top: -20px;
    background-color: #181818;
    border : ${({ isAdmin }) => (isAdmin ? '2px solid #639061;' : '2px solid #5039b7;')};
    padding: 5px 20px;
    border-radius: 50px;
    font-size: 1.2rem;
    margin-bottom: 1rem;
    color:  ${({ isAdmin }) => (isAdmin ? '#639061;' : '#fff;')};
    text-align: center;
`;


const Clients = styled.ul`
    height: 400px;
    width: 100%;
    overflow-y: scroll;
    list-style: none;
    padding: 15px;
    margin: 0;
`;

const FloatingActionButton = styled.button`
    display : ${({ isAdmin }) => (isAdmin ? 'block' : 'none')};
    position: absolute;
    bottom: 25px;
    right: 25px;
    background-color: #639061;
    border: none;
    border-radius: 50%;
    color: white;
    font-size: 0.8rem;
    cursor: pointer;
    transition: 0.1s ease-in-out;
    height: 50px;
    width: 50px;

    &:hover {
        opacity: 0.8;
        transform: scale(1.05);
    }
`;

const Popup = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #1f1f1f;
  border: 1px solid #557954;
  padding: 35px;
  border-radius: 8px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #557954;
  border-radius: 4px;
  margin-bottom: 10px;
  outline: none;
`;

const SendButton = styled.button`
  background-color: #557954;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const CloseButton = styled.button`
    position: absolute;
    top: 8px;
    right: 8px;
    height: 25px;
    width: 25px;
    background-color: #3f3f3f;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    transition: 0.1s ease-in-out;

    &:hover {
        transform: scale(1.05);
    }

    svg {
        width: 20px;
        height: 20px;
        fill: #53cc50;
    }
`;

const Clickable = styled.button`
    background-color: transparent;
    border: none;
    outline: none;
    width: 100%;
    cursor: pointer;
    transition: 0.1s ease-in-out;

    &:hover {
        transform: scale(1.01);
    }

    &:active {
        transform: scale(0.99);
    }
`;