import styled from 'styled-components';

export default function EventCard({eventInfo}) {
    return (    
        <EventCardContainer>
        <Title>{eventInfo.event.title}</Title>
        <Description>{eventInfo.event.extendedProps.description}</Description>
        <With>
            <p style={{ fontWeight: "800", fontSize:"0.6rem" }}>{eventInfo.event.extendedProps.email}</p>
        </With>
        <Time>
            {eventInfo.event.start && (
                <p style={{ fontWeight: "800" }}>
                    {eventInfo.event.start.toLocaleTimeString([], { hour: 'numeric', minute: 'numeric', hour12: true })}
                </p>
            )}
            to
            {eventInfo.event.end && (
                <p style={{ fontWeight: "800" }}>
                    {eventInfo.event.end.toLocaleTimeString([], { hour: 'numeric', minute: 'numeric', hour12: true })}
                </p>
            )}
        </Time>
    </EventCardContainer>
    );
}

const Title = styled.h3`
    font-size: 1rem;
    font-weight: 800;
    margin-bottom: 0.5rem;
`;

const Description = styled.p`
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
`;

const Time = styled.div`
  position: fixed;
  top: -30px;
  left: 0;
  right: 0;
  font-size: 0.7rem;
  font-weight: 800;
  margin-bottom: 0.5rem;
  display: none;  /* hide by default */
  justify-content: space-between;
  background-color: #181818;
  padding: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  z-index: 10;
`;

const With = styled.div`
  position: fixed;
  top: -45px;
  left: 0;
  right: 0;
  font-size: 0.7rem;
  font-weight: 800;
  margin-bottom: 0.5rem;
  display: none;  /* hide by default */
  justify-content: center;
  background-color: #181818;
  padding: 0.2rem 0.5rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  z-index: 10;
`;

const EventCardContainer = styled.div`
height: 100%;
  &:hover {
    ${Time} {
      display: flex;
    }
    ${With} {
        display: flex;
      }
  }
`;