import { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';
import styled from 'styled-components';
import toast from 'react-hot-toast';
import TrainerCard from './TrainerCard';

export default function Trainers({ session }) {
    const [profiles, setProfiles] = useState([]);
    const [email, setEmail] = useState('');
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [selectedTrainer, setSelectedTrainer] = useState(null);


    useEffect(() => {
        fetchProfiles();
    }, []);

    const openPopup = () => setPopupOpen(true);
    const closePopup = () => { setPopupOpen(false); setSelectedTrainer(null); } // Clear the selected trainer;

    const inviteUserByEmail = async (event) => {
        // Check if user already exists
        const { data: users, error: userError } = await supabase
            .from('users_info')
            .select('email')
            .eq('email', email);

        // Check for errors
        if (userError) {
            toast.error('Error checking user... \n' + userError.message);
            return;
        }

        // Check if user exists
        if (users.length > 0) {
            toast.error('User already exists');
            return;
        }

        // Create user
        const { user, error } = await supabase.auth.admin.createUser({
            email: email,
            password: 'password',
        });

        // Check for errors
        if (error) {
            toast.error('Error adding user... \n' + error.message);
        } else {
            toast.success('User added successfully!');
            fetchProfiles();
        }
    };

    const handleSendInvitation = () => {
        inviteUserByEmail(email);
        setEmail('');
        closePopup();
    };

    const handleRightClick = (trainer) => {
        setSelectedTrainer(trainer);
    };

    const handleDeleteTrainer = async () => {
        console.dir(selectedTrainer)

        try {
            const { data, error } = await supabase.auth.admin.deleteUser(selectedTrainer.id);

            if (error) {
                console.error(error);
            } else {
                toast.success('Trainer deleted successfully!');
            }
        } catch (error) {
            toast.error('error deleting Trainer');
        }
        fetchProfiles(); // Refresh the trainer list
        setSelectedTrainer(null); // Clear the selected trainer
    };

    // Fetch profiles
    const fetchProfiles = async () => {
        try {
            const { data: profiles, error } = await supabase.from('users_info').select('*').not('email', 'eq', `${session.user.email}`);
            if (error) {
                console.log(error);
            } else {
                setProfiles(profiles);
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Container>
            <Title>Trainers</Title>
            <TrainerList>
                {profiles.map(profile => <TrainerCard trainer={selectedTrainer} handleRightClick={handleRightClick} key={profile.id} profile={profile} />)}
            </TrainerList>
            <FloatingActionButton onClick={openPopup}>
                <svg width="45" height="45" fill="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 7a.625.625 0 0 1 .625.625v3.75h3.75a.624.624 0 1 1 0 1.25h-3.75v3.75a.624.624 0 1 1-1.25 0v-3.75h-3.75a.625.625 0 1 1 0-1.25h3.75v-3.75A.625.625 0 0 1 12 7Z"></path>
                </svg>
            </FloatingActionButton>
            {isPopupOpen && (
                <Popup>
                    <Title>Add Trainer</Title>
                    <CloseButton onClick={closePopup} >
                        <svg width="25" height="25" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M19.505 4.975a.6.6 0 0 1 0 .85l-13.2 13.2a.6.6 0 0 1-.85-.85l13.2-13.2a.598.598 0 0 1 .85 0Z" clip-rule="evenodd"></path>
                            <path fill-rule="evenodd" d="M5.456 4.975a.6.6 0 0 0 0 .85l13.2 13.2a.6.6 0 1 0 .85-.85l-13.2-13.2a.6.6 0 0 0-.85 0Z" clip-rule="evenodd"></path>
                        </svg>
                    </CloseButton>
                    <Input placeholder="Enter email" onChange={e => setEmail(e.target.value)} />
                    <SendButton onClick={handleSendInvitation}>Send</SendButton>
                </Popup>
            )}
            {selectedTrainer && (
                <Popup>
                    <Title>Delete Trainer</Title>
                    <CloseButton onClick={closePopup}>
                        <svg width="25" height="25" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M19.505 4.975a.6.6 0 0 1 0 .85l-13.2 13.2a.6.6 0 0 1-.85-.85l13.2-13.2a.598.598 0 0 1 .85 0Z" clip-rule="evenodd"></path>
                            <path fill-rule="evenodd" d="M5.456 4.975a.6.6 0 0 0 0 .85l13.2 13.2a.6.6 0 1 0 .85-.85l-13.2-13.2a.6.6 0 0 0-.85 0Z" clip-rule="evenodd"></path>
                        </svg>
                    </CloseButton>
                    <DeleteButton onClick={handleDeleteTrainer}>Delete <br/> {selectedTrainer.full_name}</DeleteButton>
                </Popup>
            )}
        </Container>
    );
}

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

const TrainerList = styled.ul`
    height: 400px;
    overflow-y: scroll;
    list-style: none;
    padding: 0 10px;
    margin: 0;
`;

const FloatingActionButton = styled.button`
    position: absolute;
    bottom: 25px;
    right: 25px;
    background-color: #639061;
    border: none;
    border-radius: 50%;
    color: white;
    font-size: 0.8rem;
    cursor: pointer;
    transition: 0.1s ease-in-out;
    height: 50px;
    width: 50px;

    &:hover {
        opacity: 0.8;
        transform: scale(1.05);
    }
`;

const Title = styled.h1`
    position: absolute;
    top: -20px;
    background-color: #181818;
    border: 2px solid #639061;
    padding: 5px 20px;
    border-radius: 50px;
    font-size: 1.2rem;
    margin-bottom: 1rem;
    color: #639061;
    text-align: center;
`;

const Popup = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #1f1f1f;
  border: 1px solid #557954;
  padding: 35px;
  border-radius: 8px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #557954;
  border-radius: 4px;
  margin-bottom: 10px;
  outline: none;
`;

const SendButton = styled.button`
  background-color: #557954;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const CloseButton = styled.button`
    position: absolute;
    top: 8px;
    right: 8px;
    height: 25px;
    width: 25px;
    background-color: #3f3f3f;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    transition: 0.1s ease-in-out;

    &:hover {
        transform: scale(1.05);
    }

    svg {
        width: 20px;
        height: 20px;
        fill: #53cc50;
    }
`;

const DeleteButton = styled.button`
  background-color: #d9534f;
  color: #fff;
  margin:10px 50px;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  width: max-content;

  &:hover {
    opacity: 0.8;
  }
`;
