import styled from 'styled-components';
import EventList from '../Components/EventList';
import "../assets/adminCSS.css"
import Trainers from '../Components/Trainers';

export default function AdminPanel({ session, isAdmin }) {

    if (!isAdmin) {
        window.location.href = '/overview';
    }

    return (
        <Container>
            <EventListContainer>
                <EventList />
            </EventListContainer>
            <TrainersContainer>
                <Trainers session={session}/>
            </TrainersContainer>
        </Container>
    );
}

const Container = styled.div`
    position: relative;
    height: 100%;
    height: 100svh;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 70px;
    padding:35px;
`;

const EventListContainer = styled.div`
    height: 77%;
    width: 100%;
    border: 2px solid #639061;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const TrainersContainer = styled.div`
    height: 77%;
    width: 100%;
    border: 2px solid #639061;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;