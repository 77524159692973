import Profile from './Profile';
import styled from 'styled-components';

export default function TrainerCard({profile, handleRightClick }) {
    
    const handleContextMenu = (event) => {
        event.preventDefault();
        handleRightClick(profile);
      };

    return (
        <TrainerCardContainer key={profile.id} onContextMenu={handleContextMenu}>
            <Profile url={profile.avatar_url} size={55} />
            <div>
                <p>{(profile.full_name) ? profile.full_name : "n/a"}</p>
                <p>{(profile.email) ? profile.email : "n/a"}</p>
                <p>{(profile.phone) ? profile.phone : "n/a"}</p>
            </div>
            <Button href={`https://mail.google.com/mail/?view=cm&fs=1&to=${profile.email}&su=Hey ${profile.full_name} 👋🏼`} target="_blank">
                <svg width="25" height="25" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.4 7.2a2.4 2.4 0 0 1 2.4-2.4h14.4a2.4 2.4 0 0 1 2.4 2.4v9.6a2.4 2.4 0 0 1-2.4 2.4H4.8a2.4 2.4 0 0 1-2.4-2.4V7.2ZM4.8 6a1.2 1.2 0 0 0-1.2 1.2v.26L12 12.5l8.4-5.04V7.2A1.2 1.2 0 0 0 19.2 6H4.8Zm15.6 2.86-5.65 3.39 5.65 3.476V8.859Zm-.04 8.25-6.768-4.165L12 13.9l-1.59-.954-6.77 4.164A1.2 1.2 0 0 0 4.8 18h14.4a1.2 1.2 0 0 0 1.159-.888ZM3.6 15.727l5.65-3.477L3.6 8.86v6.867Z"></path>
                </svg>
            </Button>
        </TrainerCardContainer>
    )
}

const Button = styled.a`
    position: absolute;
    right: -10px;
    bottom: -10px;
    background-color: #5e41de;
    border: none;
    border-radius: 50px;
    padding: 10px;
    color: white;
    font-size: 0.8rem;
    cursor: pointer;
    transition: 0.1s ease-in-out;

    &:hover {
        background-color: #5e41decc;
        transform: scale(1.05);
    }
`;

const TrainerCardContainer = styled.li`
    margin-bottom: 1rem;
    display: flex; 
    align-items: center;
    justify-content: space-between;
    gap:35px;
    background-color: #639061;
    padding: 25px 50px;
    border-radius: 35px;
    position: relative;
    font-size: 0.8rem;
    text-align: right;
`;