import { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';
import FullCalendar from '@fullcalendar/react';
import listPlugin from '@fullcalendar/list';
import styled from 'styled-components';

export default function EventList() {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      const { data: events, error } = await supabase.from('events').select('*');
      if (error) {
        console.log(error);
      } else {
        setEvents(events);
        console.log(events);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formatEvent = (event) => {
    return {
      id: event.id,
      email: event.profiles_email,
      title: event.title,
      start: event.start_time,
      end: event.end_time,
      description: event.description,
      profiles_id: event.profiles_id
    };
    console.table(event);
  };

  return (
    <Container>
        <Title>Daily Event List</Title>
      <FullCalendar
      height={400}
        plugins={[listPlugin]}
        events={events.map((event) => formatEvent(event))}
        eventContent={(event) => (`${event.timeText} ${event.event.extendedProps.email}`)}
        headerToolbar={{
          left: 'prev,next today',
          right: 'title',
        }}
        initialView="listDay"
        slotEventOverlap={false}
        selectable={true}
        nowIndicator={true}
        nowIndicatorContent={() => null}
      />
    </Container>
  );
}


const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  `;

  const Title = styled.h1`
    position: absolute;
    top: -20px;
    background-color: #181818;
    border: 2px solid #639061;
    padding: 5px 20px;
    border-radius: 50px;
    font-size: 1.2rem;
    margin-bottom: 1rem;
    color: #639061;
    text-align: center;
`;