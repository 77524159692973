import { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import styled from 'styled-components';
import EventListItem from '../Components/EventListItem';

export default function EventList({ session }) {
    const [events, setEvents] = useState([]);
    
    useEffect(() => {
        const fetchEvents = async () => {
          const { data: events, error } = await supabase
            .from('events')
            .select('*')
            .eq('profiles_id', session.user.id)
            .order('start_time', { ascending: true });
          
          if (error) {
            console.log(error);
          } else {
            setEvents(events);
          }
        };
        
        fetchEvents();
      }, [session]);

    function isToday(date) {
        const today = new Date();
        return date.getDate() === today.getDate() &&
               date.getMonth() === today.getMonth() &&
               date.getFullYear() === today.getFullYear();
      }
      

    return (
        <Container>
            <h1>All my events</h1>
            {events.length > 0 ? (
                <Grid>
                    {events.map((event) => (
                        <EventListItem key={event.id} event={event} isToday={isToday(new Date(event.start_time))}/>
                    ))}
                </Grid>
            ) : (
                <p>No events found.</p>
            )}
        </Container>
    );
}

const Container = styled.div`
    max-width: 1080px;
    margin: 0 auto;
    padding: 16px;
    text-align: center;
`;

const Grid = styled.div`
    height: 600px;
    width: 100%;
    padding: 17.5px;
    overflow-y: scroll;
    place-content: center;
`;
