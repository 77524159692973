import { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import styled from 'styled-components';
import '../assets/overrideCSS.css';
import Modal from '../Components/Modal';
import Calendar from '../Components/Calendar';

export default function Overview({ session, isAdmin }) {
    const [events, setEvents] = useState([]);
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    const fetchEvents = async () => {
        const { data: events, error } = await supabase
            .from('events')
            .select('*')
        if (error) {
            console.log(error);
        } else {
            setEvents(events);
            console.log(events);
        }
    };

    useEffect(() => {
        fetchEvents();
    }, [session]);

    const toggleModal = (info) => {
        console.log(info);
        if (info && info.start) {
          setStartTime(info.start);
        }
        if (info && info.end) {
            setEndTime(info.end);
        }
        setModalOpen(!modalOpen);
      };

    return (
        <>
            <Container>
               <Calendar isAdmin={isAdmin} events={events} setEvents={setEvents} toggleModal={toggleModal} session={session}  fetchEvents={fetchEvents}/>
            </Container>
            <Modal session={session} isOpen={modalOpen} onClose={toggleModal}  startTime={startTime} endTime={endTime} fetchEvents={fetchEvents}/>
        </>
    );
}

const Container = styled.div `
    height: 100%;
    height: 100svh;
    width: 100%;
    display: grid;
    place-items: center;
    text-align: center;
`;
